import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container } from '../components/Grid';
import OutboundLink from '../components/OutboundLink';

const Page = () => {
  return (
    <Layout title="Discover Data Privacy">
      <Section>
        <Container>
          <h1>Nielsen foundation privacy statement</h1>

          <p>
            <strong>Effective Date: June 2018</strong>
          </p>

          <p>
            The Nielsen Foundation cares about your privacy and wants you to be
            familiar with how we collect, use, and disclose data. The practices
            described in this Privacy Statement are undertaken by the Nielsen
            Foundation, when it receives personal data for the Discover Data
            program through Discovery Education.
          </p>

          <h2>Data we collect</h2>

          <p>
            When you contact us or make a request to us either directly or via
            Discovery Education, we may ask you to provide certain information,
            including your first name and surname, your e-mail address,
            telephone number, company/school, job title, and the content of your
            query.
          </p>

          <h2>How we use the data</h2>

          <p>The Nielsen Foundation will use the data you provide us:</p>

          <ul>
            <li>
              To respond to your questions and requests, including to connect
              you with a Nielsen associate volunteer in connection with the
              “Discover Data” Program.
            </li>
            <li>
              To send you information about the Nielsen Foundation and the
              Discover Data program, such as changes to our terms, conditions,
              and policies; and/or other administrative information.
            </li>
            <li>
              For our internal business purposes, such as data analysis, audits,
              developing new educational activities, improving our programs,
              identifying usage trends and determining the effectiveness of the
              program and our communications.
            </li>
          </ul>

          <p>&nbsp;</p>

          <h2>When we share your data</h2>

          <p>
            In connection with one or more of the purposes outlined above, we
            may disclose your data to:
          </p>

          <ul>
            <li>
              Members of the{' '}
              <OutboundLink to="http://www.nielsen.com/eu/en/privacy-policy/gdpr-operating-entities.html">
                Nielsen group of companies
              </OutboundLink>{' '}
              for the purposes described in this Privacy Statement.
            </li>
            <li>
              Third parties that provide services to us, such as (but not
              limited to) website hosting, data analysis, infrastructure, IT
              services, customer service, email delivery, and auditing.
            </li>
            <li>
              Relevant third parties as part of a Nielsen Foundation corporate
              transaction, such as a reorganization, merger, sale, joint
              venture, assignment, transfer, or other disposition of all or any
              portion of Nielsen Foundation business or assets (including in
              connection with any bankruptcy or similar proceeding).
            </li>
            <li>
              Other third parties as we believe to be necessary or appropriate:
              (a) under applicable law; (b) to comply with legal process; (c) to
              enforce our terms and conditions; (d) to protect our operations or
              those of any of our affiliates; (e) to protect our rights,
              privacy, safety, or property, and/or those of our affiliates, you,
              or others; and (f) to allow us to pursue available remedies or
              limit the damages that we may sustain.
            </li>
          </ul>

          <p>&nbsp;</p>

          <p>
            We will not share or sell your information with third parties in
            order to allow them to contact you for advertising purposes, unless
            you have asked us to do so.
          </p>

          <h2>Data security</h2>

          <p>
            We make use of appropriate technical and organizational safety
            measures to protect your personal data under our control.
            Unfortunately, no data transmission over the internet or data
            storage system can be guaranteed to be 100% secure. If you feel that
            the security of any data that we hold about you has been
            compromised, please immediately notify us of the problem by
            contacting us at Nielsen.Foundation@nielsen.com.
          </p>

          <h2>Exercising your rights</h2>

          <p>
            &lt;&gt;If you no longer want to receive communications from us, you
            can contact us and request to be removed from our Discover Data
            communications database. Please note that if you do opt-out of
            receiving communications from us, we may still send you important
            administrative messages in connection with your participation in an
            ongoing volunteer opportunity or other Nielsen Foundation event.
          </p>

          <p>&nbsp;</p>

          <h2>Data retention</h2>

          <p>
            We will retain your personal data only for those periods necessary
            to fulfill the various purposes outlined in this Privacy Statement
            unless a longer retention period is required by law.
          </p>

          <h2>Sensitive data</h2>

          <p>
            We ask that you not send any sensitive personal data (e.g.,
            information related to racial or ethnic origin, political opinions,
            religious or other beliefs, health, criminal background, trade union
            membership, or government-issued identification numbers). If we do
            receive any sensitive personal data about you, we will, where
            necessary, obtain your explicit consent to collect and use such
            information in accordance with applicable law.
          </p>

          <h2>Contacting us</h2>

          <p>
            After reading this Privacy Statement, if you still have questions
            about our privacy practices, please contact us at:
            Nielsen.Foundation@nielsen.com
          </p>
        </Container>
      </Section>
    </Layout>
  );
};

export default Page;
